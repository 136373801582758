import * as PATHS from 'constants/ApplicationsPath';
import { getOidcBaseConfig } from 'constants/Oidc';
import { AuthProvider, initUserManager } from 'oidc-react';
import BlipShop from 'pages/BlipShop';
import EmptyRoute from 'pages/EmptyRoute';
import MainContainer from 'pages/MainContainer';
import PackManager from 'pages/PackManager';
import PackSpace from 'pages/PackSpace';
import SelfOnboarding from 'pages/SelfOnboarding';
import TrackingRoute from 'pages/TrackingRoute';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initializeSegment } from 'services/Segment';
import { CheckNonAuthAccess } from 'utils/CheckNonAuthAccess';

const App: React.FC = () => {
  const urlRedirect = window.location.href;
  const oidc = getOidcBaseConfig(urlRedirect);
  const userManager = initUserManager({ ...oidc });

  userManager.settings.extraQueryParams = {
    journey_simple: 1,
  };

  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('domain_hint')) {
    const domainHintSSO = searchParams.get('domain_hint');

    userManager.settings.extraQueryParams['domain_hint'] = domainHintSSO;
  }

  const isNonAuthAccess = CheckNonAuthAccess();

  useEffect(() => {
    initializeSegment();
  }, []);

  //TODO: After migrating to a new url (https://app.packs.blip.ai/), can remove the if/else below
  //Keeping only the START_PATH_NAME and MANAGER_PATH_NAME
  const routesWithAuthProvider = () => (
    <AuthProvider userManager={userManager}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContainer />}>
            <Route index element={<EmptyRoute />} />
            <Route path={`/${PATHS.BLIPSHOP_PATH_NAME}/*`} element={<BlipShop />} />
            <Route path={`${PATHS.PACKSPACE_PATH_NAME}/*`} element={<PackSpace />} />
            {window.location.href.includes('app.') ? (
              <>
                <Route path={`${PATHS.START_PATH_NAME}/*`} element={<SelfOnboarding />} />
                <Route path={`${PATHS.MANAGER_PATH_NAME}/*`} element={<PackManager />} />
              </>
            ) : (
              <>
                <Route path={`${PATHS.ONBOARDING_PATH_NAME}/*`} element={<SelfOnboarding />} />
                <Route path={`${PATHS.PACKMANAGER_PATH_NAME}/*`} element={<PackManager />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
  return isNonAuthAccess ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<EmptyRoute />}></Route>
        <Route path={`/${PATHS.BLIPSHOP_PATH_NAME}/*`} element={<TrackingRoute />}></Route>
      </Routes>
    </BrowserRouter>
  ) : (
    routesWithAuthProvider()
  );
};

export default App;
